<template>
  <v-container fluid>
    <div class="version">
      Règlement officiel des AGPA – Version du 28 décembre 2019
    </div>

    <v-expansion-panels style="padding: 10px; max-width: 800px; margin: auto">
      <!-- I.1. Présentation des AGPA -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">I.1.</i> Présentation des AGPA</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Ouvert à tous les membres du forum Absolument G, le concours des Absolument G Photos Awards (AGPA)
            récompense les meilleures photos parmi celles proposées par les participants pour huit catégories.
          </p>
          <p>Liste et description des catégories retenues :</p>

          <v-card style="width: 100%;">
            <v-simple-table
              class="categories"
              style="width: 100%; text-align: left;"
            >
              <tr><th>Portrait</th><td>Photos mettant en évidence un personnage unique de plus de douze ans (âge pris lors de l'année en cours)</td></tr>
              <tr><th>Groupe et événement</th><td>Photos de groupes avec au minimum deux individus sur la photo. Peut importe l'âge, il peut s'agir aussi bien d'enfants que d'adultes</td></tr>
              <tr><th>Enfant</th><td>Portraits ou photos de groupe d'enfants de douze ans ou moins (âge pris lors de l'année en cours)</td></tr>
              <tr><th>Nature</th><td>Photos mettant l'accent sur un sujet ou phénomène naturel (végétal, animal, minéral, météorologique, ...). Les paysages naturels sont aussi acceptés</td></tr>
              <tr><th>Grand angle</th><td>Tous types de paysages et panoramas</td></tr>
              <tr><th>Manus Hominum</th><td>Photos dont le sujet principal est marqués par l'emprunte de l'Homme (villes, monuments, ouvrage d'art, machinerie, objets confectionnés par l'homme, ...)</td></tr>
              <tr><th>Spéciale</th><td>Chaque année, un thème particulier est choisi</td></tr>
              <tr><th>Autre regard</th><td>Photos humoristiques, artistiques, décalées ou retouchées, ainsi que toutes celles n'ayant pas trouvées leurs places dans les autres catégories</td></tr>
            </v-simple-table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- I.2. Historique -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">I.2.</i> Historique</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <dl>
            <dt>2006</dt>
            <dd>
              <ul>
                <li>La première édition des AGPA a eu lieu en décembre 2006, sur une idée originale d’Emmanuel Gueudelot;</li>
                <li>L’organisation du concours et de la cérémonie de remise des récompenses sont assurées depuis 2006 par Olivier Gueudelot, webmaster du site Absolument G, lui-même assisté par d’autres membres du forum;</li>
                <li>Les principes fondamentaux des AGPA ont été définis collectivement par de nombreux membres du forum Absolument G entre septembre et décembre 2006.</li>
              </ul>
            </dd>
            <dt>2007</dt>
            <dd>
              <ul>
                <li>La version 2007 du règlement reprend ces principes en y intégrant les évolutions suggérées par les participants à l’issue de la première édition des AGPA;</li>
              </ul>
            </dd>
            <dt>2008</dt>
            <dd>
              <ul>
                <li>Suite à de nouvelles suggestions des participants, la version 2008 du règlement a également évolué sur certains points (affichage du titre des photos, règles de comparaison des photos de catégories différentes, critères d’attribution de l’AGPA d’Or du meilleur photographe);</li>
              </ul>
            </dd>
            <dt>2012</dt>
            <dd>
              <ul>
                <li>Ajout des catégories Enfants et Spéciale (dont le thème sera choisi chaque année par les participants).</li>
              </ul>
            </dd>
            <dt>2013</dt>
            <dd>
              <ul>
                <li>Modification des catégories historiques, mise en place nouvelle version du site des AGPA (V4);</li>
                <li>Systeme de vote du meilleur titre intégré.</li>
              </ul>
            </dd>
            <dt>2016</dt>
            <dd>
              <ul>
                <li>Précision concernant les votes des enfants, qui désormais ont le droit de voter, mais dont les votes ne sont pas pris en compte pour le calcul des récompenses.</li>
              </ul>
            </dd>
            <dt>2019</dt>
            <dd>
              <ul>
                <li>
                  Précision concernant les catégories Groupe et événements et Enfant.
                  Désormais les enfants et groupes d'enfants sont autorisés dans la catégorie Groupe et événements, et la catégorie Enfants concerne plutôt les portraits d'enfants de moins de 12 ans;
                </li>
                <li>Changement du barême d'attribution des points pour le palmarès (avec application rétroactive), afin de récompenser aussi les photos nominées (1 point). </li>
              </ul>
            </dd>
          </dl>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- I.3. Organisation du concours -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">I.3.</i> Organisation du concours</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            L’édition des AGPA se déroule en 5 phases qui sont détaillées ci-dessous dans les sections II.2. à II.6.
            Les dates et heures indiquées ne sont pas immuables ; elles pourront être modifiées si les circonstances l’exigent.
            Les candidats seront alors prévenus sur le forum Absolument G, et par le site des AGPA lui même.
          </p>

          <v-card style="width: 100%; padding: 15px">
            <table style="width: 100%; text-align: left;">
              <tr><td>Phase n°1</td><td>1er oct. à 00h00</td><td>Enregistrement des photos</td></tr>
              <tr><td>Phase n°2</td><td>19 déc. à 00h00</td><td>Validation des photos</td></tr>
              <tr><td>Phase n°3</td><td>20 déc. à 00h00</td><td>Votes</td></tr>
              <tr><td>Phase n°4</td><td>24 déc. à 00h00</td><td>Dépouillement</td></tr>
              <tr><td>Phase n°5</td><td>25 déc. à 20h30</td><td>Cérémonie des AGPA</td></tr>
            </table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.1. Participation et conditions générales -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.1.</i> Participation et conditions générales</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Le concours est ouvert à tous les membres du forum Absolument G. Il n'y a aucune limite d'âge,
            les enfants comme les adultes peuvent aussi participer au concours.
          </p>
          <p>Chaque candidat peut présenter une ou deux photo(s) par catégorie, pour autant de catégories qu’il le souhaite. </p>
          <p>
            Pour pouvoir être prise en compte, chaque photo doit remplir les conditions suivantes:
            <ul>
              <li>
                Avoir été prise par le candidat au cours de l'année. Les photos du mois de décembre de l'année précédente sont acceptées;
              </li><li>Les photos prises au retardateur sont admises, si le candidat a préparé l’appareil et cadré la photo lui-même;</li>
              <li>Ne pas être un photo-montage, sauf pour la catégorie &laquo; Autre regard &raquo; où ils sont autorisés.</li>
            </ul>
          </p>

          <p>
            Les effets suivants ne sont pas considérés comme effets de montage et sont acceptés pour toutes les catégories:
            <ul>
              <li>Le redimensionnement ou le recadrage;</li>
              <li>Les rotations ou la symétrie;</li>
              <li>Les réglages de saturation, de luminosité ou de contraste;</li>
              <li>L’effet noir et blanc, sépia ou négatif;</li>
              <li>La correction des yeux rouges ou l’effacement de poussières.</li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.2. Phase 1: Enregistrement des photos -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.2.</i> Phase 1: Enregistrement des photos</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            L’enregistrement des photos se fait sur Internet, dans la section du site Absolument G consacrée aux AGPA.
            Seules les photos numériques ou numérisées sont acceptées (les diapositives et photos argentiques doivent être scannées).
          </p>
          <p>
            Lors de l’enregistrement des photos, il est demandé au candidat d’attribuer un titre à chacune de ses œuvres.
            Le titre donné est visible lors de l'évaluation des jurés, ils pourront donc en tenir compte s' ils le souhaitent.
          </p>
          <p>
            Durant cette phase, le candidat peut:
            <ul>
              <li>Envoyer et nommer de nouvelles photos (dans la limite de deux par catégorie);</li>
              <li>Supprimer, remplacer ou renommer les photos déjà envoyées.</li>
            </ul>
          </p>
          <p>
            Techniquement, les photos doivent être au format jpg. Il n'y a pas d'autres contraintes techniques, si ce n'est qu'une photo, comme toutes
            les fichiers enregistrés sur le site, doit faire moins de 200Mo.
            Lors de l'enregistrement, les photos trop grosses seront redimmensionnées automatiquement par le site Absolument G. Si toutefois vous voulez gérer
            vous-mêmes le redimmensionnement, il est conseillé de fournis des photos en haute résolution, c'est à dire à minima <b>FULL HD</b> (1920 x 1080).
            Mais du <b>4K</b> (3840 x 2160) serait préférable afin de permettre à ceux qui le souhaiterais
            de profiter d'une projection des photos en hautes qualités ou d'en faire des tirages.
            De plus à l'avenir, les téléphones portables et ordinateurs utiliseront couramment cette résolution.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.3. Phase 2: Validation des photos -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.3.</i> Phase 2: Validation des photos</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Une fois la phase d'enregistrement terminée, la phase de vérification des photos débute.
            Durant cette phase d'une journée en général, les photos sont affichées sur le site Absolument G, dans la section consacrée aux AGPA.
          </p>
          <p>
            Pendant cette période, les membres du forum peuvent:
            <ul>
              <li>Visualiser toutes les photos de tous les candidats;</li>
              <li>Contacter l’organisation pour signaler une erreur ou une photo ne correspondant pas aux critères définis.</li>
            </ul>
          </p>
          <p>Si une photo est jugée non conforme aux critères définis, elle sera refusée et exclue du concours.</p>
          <p>
            L'utilisateur pourra demander à remplacer l'œuvre qui pose problème.
            Cependant, cette opération étant manuelle et très technique, seul Olivier peut s'en occuper. C'est pourquoi aucune
            garanti n'est donnée sur ce point. Celà dépend uniquement de la disponibilité d'Olivier lors de la phase 2.
          </p>
          <p>Il n’est pas possible de voter pendant la phase de vérification.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.4. Phase 3: Votes -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.4.</i> Phase 3: Votes</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Le vote est ouvert à tous les membres du forum Absolument G. Même les enfants de moins de 12 ans
            peuvent voter, cependant seuls les votes des jurés ayant 12 ans ou plus au cours de l'année sont pris en compte lors du décompte.
          </p>
          <p>La phase de vote dure en général 3 jours. </p>
          <p>
            Pendant cette période, les membres du forum peuvent:
            <ul>
              <li>Visualiser les photos de tous les candidats. A noter que les photos refusées lors de la phase 2 ne seront pas visibles;</li>
              <li>
                Voter pour les meilleures photos parmi celles envoyées par les autres candidats, en attribuant pour chaque catégorie:
                <ul>
                  <li>1 ou 2 étoiles aux meilleures photos;</li>
                  <li>1 plume pour les meilleurs titres de photo.</li>
                </ul>
              </li>
              <li>Toutes les photos qui n'ont pas été refusées lors de la phase précédente de vérification, sont considérées comme valables;</li>
              <li>Corriger leurs votes.</li>
            </ul>
          </p>
          <p>
            Les candidats qui votent, doivent attribuer un minimum de points dans chaque catégorie.
            S'ils ne le font pas, pour les catégories concernées, leurs votes partiels ne seront pas pris en compte.
            Un indicateur (en haut à droite) dans le formulaire de vote permet de savoir le nombre de points que l'on peut attribuer pour chaque catégorie
            Ainsi que le nombre de point que l'on a déjà attribué. Cet indicateur passe du rouge au vert quand un nombre suffisant de points
            a été attribués.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5. Phase 4: Dépouillement -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.</i> Phase 4: Dépouillement</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Le dépouillement va compter les votes reçues par chaque photo et établir un classement à la
            fois au sein de la catégorie de la photo mais aussi globalement (toutes catégories confondues).
            En cas d'égalité, le nombre total de votes reçus fait la différence (les photos ayant reçu le plus de votes passant devant en ayant reçu le moins).
          </p>

          <p>
            A partir de l'édition 2008 des AGPA, une note unique est calculée pour chaque photo.
            Cette note dépend des deux critères de choix précédemment définis (nombre de points et nombre de votes),
            mais également du nombre de photos de la catégorie à laquelle elle appartient et du nombre de jurés de l'édition du concours.
            Cette variable a été créée pour permettre de comparer les photos entre elles à partir d’un chiffre unique,
            quelques soient la catégorie ou l'édition pour lesquelles elles concourent.
          </p>

          <p>
            Remarques :
            <ul>
              <li>
                Si cette note technique n'a pas vocation à être diffusée lors de la cérémonie des AGPA,
                elle pourra être communiquée à chaque participant en faisant la demande;
              </li>
              <li>
                Le palmarès des deux premières éditions ne sera pas modifié, même s’il aurait été différent avec ce mode d’évaluation,
                qui tient compte des nombres différents de photos entre les catégories.
              </li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.a. Calcul des points -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.a.</i> Calcul des notes</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>Le calcul des notes pour les photographies et le suivant :</p>
          <img
            src="/img/agpa/f1.gif"
            alt="Note = NotePts + NoteVts"
          ><br>
          <img
            src="/img/agpa/f2.gif"
            alt="NotePts = PtsPhoto x NbPhotos/PtsCatégorie x 10^7/1001"
          ><br>
          <img
            src="/img/agpa/f3.gif"
            alt="NoteVts = VtsPhoto x NbPhotos /VtsCatégorie x 10^4/1001 "
          ><br>

          <table style="width: 100%; text-align: left; margin: 15px 0">
            <tr><td><i>PtsPhoto</i></td><td>nombre total de points recueillis par la photo</td></tr>
            <tr><td><i>PtsCatégorie</i></td><td>nombre total de points recueillis par l’ensemble des photos de la catégorie</td></tr>
            <tr><td><i>VtsPhotos</i></td><td>nombre total de votes recueillis par la photo</td></tr>
            <tr><td><i>PtsCatégorie</i></td><td>nombre total de votes recueillis par l’ensemble des photos de la catégorie</td></tr>
            <tr><td><i>Nbphotos</i></td><td>nombre de photos de la catégorie</td></tr>
          </table>


          <p>
            Remarques :<ul>
              <li>Les coefficients servent à départager les photos et rendre la note globale plus lisible qu’un chiffre à virgule;</li>
              <li>La note des points (<i>NotePts</i>), affectée du plus fort coefficient, est la note déterminante, la note des votes (<i>NoteVts</i>) ne servant qu’à départager les photos à égalité avec le premier critère;</li>
              <li>La fusion des deux notes <i>NotePts</i> et <i>NoteVts</i> en  une variable unique permet de simplifier les calculs et les comparaisons, sans remettre en cause des critères historiques de classement.</li>
              <li>La note moyenne de chaque catégorie est de 10 000 points.</li>
            </ul>
          </p>

          <p>
            En cas de notes équivalentes, deux photos sont départagées par les critères suivant (par ordre d’application):<ul>
              <li>Présence ou non de titre (avantage à la photo titrée);</li>
              <li>Nombre total de photos de la catégorie (avantage à la photo provenant de la plus grande catégorie);</li>
              <li>Edition (pour les comparaisons entre éditions; avantage à la photo de l’édition la plus récente);</li>
              <li>Palmarès des auteurs des photos avant l’édition en cours (avantage à la photo dont l’auteur présente le plus faible palmarès ; cf. paragraphe III.);</li>
              <li>Tirage au sort.</li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.b. AGPA du meilleur titre -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.b.</i> AGPA du meilleur titre</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Depuis 2011, les jurés ont la possibilité de récompenser les photos ayant le meilleur titre en leur attribuant une plume.
            Chaque juré peut ainsi voter pour 5 à 10 photos parmis toutes les photos proposées d'une édition.
          </p>
          <p>
            Comme pour les étoiles, il y a certaines conditions à respecter pour que les votes "plumes" d'un juré soient pris en compte:<ul>
              <li>Le juré doit avoir plus de 12 ans;</li>
              <li>Le juré doit avoir attribué au moins 5 plumes;</li>
              <li>Le juré doit avoir attribué au maximum 10 plumes.</li>
            </ul>
          </p>
          <p>Ensuite les photos sont classées en fonction du nombre de plumes qu'elles totalisent.</p>
          <p>
            En cas d'exaequo, deux photos sont départagées par les critères suivant (par ordre d’application):<ul>
              <li>Avantage à la photo ayant obtenu le plus petit score (voir section II.5.a. sur le calcul des points);</li>
              <li>Avantage à l'auteur ayant le moins bon palmarès sur l'édition en cours;</li>
              <li>Avantage à l'auteur ayant le moins bon palmarès cumulé sur les éditions précédantes;</li>
              <li>Tirage au sort.</li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.c. AGPA de la meilleure photographie -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.c.</i> AGPA de la meilleure photographie</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            L'AGPA de la meilleure photographie récompense les photos qui ont obtenues les plus grands scores toutes catégories confondues.
            Le score dont le calcul est expliqué dans la section II.5.a. prend déjà en compte cette aspect là.
            En effet, le score moyen pour chaque catégories étant de 10 000 points, on peut ainsi comparer toutes les photos, mêmes celles d'édition et de catégorie différentes.
          </p>
          <p>
            A noter que le calcul du score favorise les photos provenant de la catégorie ou il y a le plus de photo.
            L'algorithme pour départager les exaequos est le même que celui présenté dans la section II.5.a. pour départager les photos au sein d'une même catégorie.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.d. AGPA du meilleur photographe -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.d.</i> AGPA du meilleur photographe</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Le classement du meilleur photographe est calculé à partir de la somme des notes des 8 meilleures photos (8 étant le nombre de catégories du concours) de chaque participant,
            ou de toutes les photos des participants ayant proposés 8 photos ou moins.
          </p>
          <p>
            En cas de notes équivalentes, deux photographes sont départagés par les critères suivants (par ordre d’application):<ul>
              <li>Moyenne des photos du photographe pour l’édition en cours (avantage à la meilleure moyenne);</li>
              <li>Note de la moins bonne photo (avantage à la meilleure des deux);</li>
              <li>Palmarès de l’édition actuelle (hors catégorie spéciales), avantage au photographe possédant le meilleur palmarès;</li>
              <li>Palmarès cumulé des éditions précédentes (avantage au candidat présentant le plus faible palmarès);</li>
              <li>Tirage au sort.</li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.e. AGPA d'honneur -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.e.</i> AGPA d'honneur</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Les enfants de moins de 12 ans qui participent à une édition en proposant des photos
            gagneront un AGPA d'honneur pour leur participation si jamais leurs photos ne remportent aucune récompense.
            Cet AGPA est attribué à la photo ayant reçu le plus de vote, ou bien est tirée au hasard parmi ses photos.
          </p>
          <p>Un AGPA d'honneur de donne aucun point de palmarès (c'est à dire aucun points pour le classement général, cf paragraphe III.).</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.5.f. AGPA de diamant -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.5.f.</i> AGPA de diamant</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Dans certains cas bien précis, les AGPA d’or peuvent se transformer en AGPA de diamant: <ul>
              <li>
                AGPA de diamant de la meilleure photo de sa catégorie attribué à la première photo si: <ul>
                  <li>Elle totalise 50 000 points ou plus;</li>
                  <li><b>ET</b> la note de la photo est strictement supérieure au double de la note de la deuxième meilleure photo de la catégorie.</li>
                </ul>
              </li>
              <li>AGPA de diamant du meilleur titre est attribué si la photo récolte plus du double de plume par rapport au deuxième meilleur titre.</li>
              <li>
                AGPA de diamant de la meilleure photo toutes catégories attribué à la meilleure photo si : <ul>
                  <li>La photo est AGPA de diamant de sa catégorie et a reçu le maximum de votes possible (= nombre de jurés en age de voter moins un).</li>
                </ul>
              </li>
              <li>
                AGPA de diamant du meilleur photographe attribué au premier photographe si :<ul>
                  <li>Il est l’auteur des trois meilleures photos et ses quatre meilleures photos totalisent 100 000 points ou plus ;</li>
                  <li><b>OU</b> il totalise 33 points ou plus au palmarès de l’édition actuelle (avant transformation de l’AGPA d’or du meilleur photographe en AGPA de diamant).</li>
                </ul>
              </li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- II.6. Phase 5: Cérémonie des AGPA -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">II.6.</i> Phase 5: Cérémonie des AGPA</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Le palmarès sera dévoilé au public lors d'une cérémonie organisé pour l'occasion. En dehors de l’honorifique attribution des AGPA d’or,
            aucune récompense n’est assurée. Les photos gagnantes seront cependant durablement mises en ligne sur le site Absolument G.
          </p>

          <p>
            Depuis 2019, la cérémonie est générée automatiquement en ligne. De ce fait:
            <ul>
              <li>Les personnes non présentes lors de la cérémonie peuvent la suivre en ligne, en direct ou en différé;</li>
              <li>Moins de travail pour Olivier qui n'a plus à créer à la main la présentation de la cérémonie;</li>
              <li>Il est possible de revoir les anciennes cérémonie dans la séction du site Absolument G dédiée à celà.</li>
            </ul>
          </p>


          <p>
            Le total des points gagnés détermine le classement d’une photo dans sa catégorie (la photo gagnante est celle qui a obtenu le plus de points).
            Les cas particuliers (ex-æquo, comparaisons inter catégories) sont détaillés dans le paragraphe <i>II.5</i>.
          </p>

          <p>
            Les quatre meilleures photos de chaque catégorie seront nominées à la cérémonie.
            La meilleure d’entre elle sera récompensée d’un AGPA d’or ou de diamant, puis un AGPA d'argent et bronze pour la deuxième et troisième.
          </p>

          <p>
            En plus des AGPA d’or, d'argent et de bronze remient pour chacune des huit catégories, trois autres AGPA sont décernées :
            <ul>
              <li>Les AGPA du meilleur titre <b>toutes catégories</b> (récompense les photos dont le titre à le plus eu de succès) ;</li>
              <li>Les AGPA de la meilleure photo <b>toutes catégories</b> (récompense les photos ayant obtenues les plus grands scores parmi l'ensemble des photos postées) ;</li>
              <li>Les AGPA du meilleur <b>photographe</b> (récompense les photographes dont les six meilleures photos totalisent le plus grand score).</li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>


      <!-- III. Palmarès -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><i style="display: inline-block; width: 40px">III.</i> Palmarès</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Un système de points permet de représenter en un chiffre le palmarès accumulé par chaque participant.
            Ce système permet notamment de départager les ex-æquo (en favorisant le palmarès cumulé le plus faible) ou d’établir un palmarès général,
            toutes éditions confondues. Ces points sont attribués à chaque candidat en fonction des récompenses reçues, selon le barème suivant :
          </p>
          <v-card style="width: 100%;">
            <table style="width: 100%; text-align: center;">
              <tr><th>AGPA de diamant</th><th>AGPA d’or</th><th>AGPA d’argent</th><th>AGPA de bronze</th><th>Nominé</th></tr>
              <tr><td>6</td><td>4</td><td>3</td><td>2</td><td>1</td></tr>
            </table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>


<script>
export default  {
    data: () => ({

    })
};
</script>

<style lang="scss" scoped>
@import '../../themes/global.scss';

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    text-align: center;
    color: $primary;
    text-shadow: 0 -1px #000;
    text-shadow: 0 1px #aaa;
    font-size: 40px;
    font-family: "Comfortaa", sans-serif;
    margin: 20px 0 60px 0;
}

dt {
    font-weight: bold;
}
dd {
    margin-left: 20px;
    border-left: 3px solid #ddd;
    padding-left: 10px;


    ul {
      margin-left: 0;
    }

    li {
        margin-left: -23px;
        padding-left: 0;
        list-style-image: url('/img/agpa/puce.png');
    }
}


.categories {
    th {
        min-width: 150px;
        vertical-align: top;
        text-align: right;
        padding: 5px 0;
    }
    td {
        font-style: italic;
        vertical-align: top;
        padding: 5px 16px;
    }
}


li {
    list-style-image: url('/img/agpa/puce.png');
}

.version {
        font-family: 'Courier New', Courier, monospace!important;
        padding: 10px;
        font-size: 15px;
        text-align: center;
    }


.reglement {
    // font-family: "Tangerine", serif;
    // font-size: 30px;
    text-align: justify;
    max-width: 800px;
    margin: auto;



    .number {
        font-family: 'Times New Roman', Times, serif!important;
        font-style: italic;
        font-weight: 100;
    }

    td {
        text-align: center;
    }
}
</style>
